$gray: #2d2d2d;
$light-gray: #7e7e7e;
$very-light-gray: #f5f5f5;
$black: #121212;
$white: white;
/* new */
$gray: #CACACA;
$brand-blue: #1177FF;
$brand-blue-darker: #005FDD;
$brand-complementary: #FF9911;
$cyan: #11EEFF;
$white: #FFF;
$warning: crimson;