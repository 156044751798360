#user-messages {

	.accordion:not(:last-child) {
		margin-bottom: .75rem;
	}

	.card-header {
		display: flex;
		align-items: center;
		padding: 0;

		.card-title {
			flex: 1;
			margin: 0;
			cursor: pointer;
		}

		.card-controls {

			.btn:not(:last-child) {
				margin-right: .5rem;
			}
		}

		.card-title,
		.card-controls {
			padding: .75rem 1rem;
		}
	}
}