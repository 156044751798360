@import '../../vars';

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100vh;

	& > header {
		display: flex;
		justify-content: space-between;
		background-color: $brand-blue;
		padding: 10px 40px;

		.logo-img {
			height: 40px;
		}

		#user-bar {
			display: flex;
			align-items: center;
			height: 100%;

			.user-bar__action {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				height: 2rem;
				width: 2rem;
				border-radius: .25rem;
				background-color: transparent;
				transition: all .2s ease-out;
				font-size: 1rem;
				color: $white;
				cursor: pointer;

				&:hover {
					background-color: darken($brand-blue, 10%);
					color: lighten($brand-complementary, 30%);

					.user-bar__action__notification {
						opacity: 0;
					}
				}

				&:not(:last-child) {
					margin-right: 3px;
				}

				.user-bar__action__notification {
					$size: .85rem;

					display: flex;
					align-items: center;
					justify-content: center;
					height: $size;
					width: $size;
					position: absolute;
					top: 1px;
					right: 2px;
					color: $white;
					font-weight: bold;
					border-radius: 1rem;
					font-size: .65rem;
					background-color: $brand-complementary;
					opacity: 1;
					transition: opacity .2s ease-out;
				}
			}
		}
	}

	& > main {
		flex: 1;
	}
}