@import "./_vars.scss";

$primary: $brand-blue;
$secondary: $brand-complementary;

@import "~bootstrap/scss/bootstrap";

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-track {
	background-color: $very-light-gray;
}

::-webkit-scrollbar-corner,
::-webkit-resizer {
	background: $very-light-gray;
}

::-webkit-scrollbar-thumb {
	background-color: $light-gray;
}

body {
	--ck-border-radius: 0.25em;
	--ck-color-toolbar-border: #{$black};
	--ck-color-base-border: #{$black};
	--ck-focus-ring: 1px solid #{$brand-blue};

	a {
		outline-color: $brand-blue;
		color: $black;
		transition: color 0.25s ease-out;

		&:hover {
			color: $brand-blue;
		}

		&.MuiButton-root {
			text-decoration: none;
			color: inherit;
			color: white;

			&.MuiLink-underlineHover {
				text-decoration: none;
			}
		}
	}

	input[type="text"],
	input[type="email"],
	input[type="number"],
	input[type="password"],
	textarea {
		padding: 0.5rem 0.75rem;
		font-size: 1rem;
		line-height: 1.5;
		border-radius: 0.25rem;
		border: 1px solid $black;
		width: 100%;

		&[disabled] {
			position: relative;
			opacity: 0.5;
		}
	}

	a.side-menu__item--active {
		background-color: rgba($brand-blue, 0.7);
		color: $white;
		transition: background-color 0.2s ease-out, width 0.25s;

		&:hover {
			background-color: rgba($brand-blue, 1);
			color: $white;
		}
	}

	.nav-pills a.nav-link.active {
		color: white;
	}

	.centered-block {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		width: 100%;
		height: 100%;
		padding-top: 10rem;

		&.loading {
			color: $brand-blue;
		}
	}

	.form {
		&.form--narrow {
			width: 26rem;
			max-width: 450px;
		}

		&.form--floating {
			box-shadow: 0 0 12px rgba($gray, 0.3);
			border-radius: 0.25rem;

			.form__content {
				padding: 2rem 1.75rem;
			}
		}

		&.form--two-cols {
			.row {
				max-width: calc(340px * 2 + 4rem);

				.col {
					&:not(:last-child) {
						margin-right: 4rem;
					}
				}
			}
		}

		&.form--with-error {
			.form__content {
				padding-top: 1rem;
			}
		}

		.form__content {
			& > h2:first-child {
				margin-top: 0;
			}
		}

		.form__field {
			display: flex;
			flex-direction: column;

			&.form__field--inside-icon {
				position: relative;

				& > input {
					padding-right: 2.5rem;
				}

				& > .btn {
					position: absolute;
					top: 0.3rem;
					right: 0.3rem;
				}
			}

			&:not(:first-child) {
				margin-top: 1.3rem;
			}

			& > label {
				margin-bottom: 0.5rem;
				font-weight: bold;
			}

			&.disabled > *:not(label) {
				opacity: 0.5;
				pointer-events: none;
			}
		}

		.form__message {
			margin: 0.75rem 0 1.5rem 0;
		}

		.form__error {
			background-color: rgba($brand-complementary, 0.2);
			border-bottom: 1px solid rgba($brand-complementary, 0.3);
			color: darken($brand-complementary, 35%);
			font-size: 1.05rem;
			line-height: 1.5;
			padding: 0.6rem 0.75rem;
			border-radius: 0.25rem 0.25rem 0 0;
		}

		.form__buttons {
			margin-top: 1.3rem;

			.btn {
				&:not(:first-child) {
					margin-left: 1rem;
				}
			}
		}
	}

	.global-alert {
		position: fixed;
		top: 4rem;
		left: 50%;
		opacity: 0;
		transform: translateX(-50%) translateY(-0.5rem);
		padding: 0.75rem 4rem;
		background-color: rgba($black, 0.8);
		border-radius: 0.25rem;
		color: white;
		z-index: 9999;
		border-bottom: 5px solid transparent;
		transition: all 0.5s ease-in-out;

		&.global-alert--visible {
			opacity: 1;
			transform: translateX(-50%) translateY(0);
		}

		&.global-alert--success {
			border-color: $green;
			padding-left: 5.5rem;

			& > svg {
				color: $green;
			}
		}

		&.global-alert--error {
			border-color: $red;
			padding-left: 5.5rem;

			& > svg {
				color: $red;
			}
		}

		& > svg {
			position: absolute;
			top: 0.75rem;
			left: 1rem;
			font-size: 1.5rem;
		}
	}

	.color--light {
		color: $light-gray;
	}

	.text--center {
		text-align: center;
	}

	.font--small {
		font-size: 0.75rem;
	}

	.font--medium {
		font-size: 1.25rem;
	}

	.font--big {
		font-size: 1.5rem;
	}

	.font--huge {
		font-size: 2rem;
	}

	.padding {
		padding: 1rem;
	}

	.padding--double {
		padding: 2rem;
	}

	.padding--half {
		padding: 0.5rem;
	}

	.block-title {
		font-weight: bold;
		text-transform: uppercase;
		font-size: 1.25em;
	}

	// react-select-search doesn't come with styling by default
	.select-search {
		position: relative;
		box-sizing: border-box;
	}

	.select-search *,
	.select-search *::after,
	.select-search *::before {
		box-sizing: inherit;
	}

	.select-search__value {
		position: relative;
		z-index: 1;
	}

	.select-search__value::after {
		content: "";
		display: inline-block;
		position: absolute;
		top: calc(50% - 9px);
		right: 19px;
		width: 11px;
		height: 11px;
	}

	.select-search__input {
		display: block;
		background: $white;
		outline: none;
		text-align: left;
		text-overflow: ellipsis;
		-webkit-appearance: none;
		padding: 0.5rem 0.75rem;
		font-size: 1rem;
		line-height: 1.5;
		border-radius: 0.25rem;
		border: 1px solid $black;
		width: 100%;
	}

	.select-search__input::-webkit-search-decoration,
	.select-search__input::-webkit-search-cancel-button,
	.select-search__input::-webkit-search-results-button,
	.select-search__input::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}

	.select-search__input:not([readonly]):focus {
		cursor: initial;
	}

	.select-search__select {
		background: $white;
		box-shadow: 0 0.0625rem 0.125rem rgba($black, 0.5);
	}

	.select-search__options {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.select-search__row:not(:first-child) {
		border-top: 1px solid rgba($black, 0.5);
	}

	.select-search__option,
	.select-search__not-found {
		display: block;
		height: 36px;
		width: 100%;
		padding: 0 16px;
		background: $white;
		border: none;
		outline: none;
		font-size: 14px;
		text-align: left;
		cursor: pointer;
	}

	.select-search--multiple .select-search__option {
		height: 48px;
	}

	.select-search__option.is-selected {
		background: $brand-blue;
		color: $white;
	}

	.select-search__option.is-highlighted,
	.select-search__option:not(.is-selected):hover {
		background: rgba($brand-blue, 0.3);
	}

	.select-search__option.is-highlighted.is-selected,
	.select-search__option.is-selected:hover {
		background: rgba($brand-blue, 0.8);
		color: $white;
	}

	.select-search__group-header {
		font-size: 10px;
		text-transform: uppercase;
		background: $black;
		padding: 8px 16px;
	}

	.select-search.is-disabled {
		opacity: 0.5;
	}

	.select-search.is-loading .select-search__value::after {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
		background-size: 11px;
	}

	.select-search:not(.is-disabled) .select-search__input {
		cursor: pointer;
	}

	.select-search--multiple {
		border-radius: 3px;
		overflow: hidden;
	}

	.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
		transform: rotate(45deg);
		border-right: 1px solid #000;
		border-bottom: 1px solid #000;
		pointer-events: none;
	}

	.select-search--multiple .select-search__input {
		cursor: initial;
	}

	.select-search--multiple .select-search__input {
		border-radius: 3px 3px 0 0;
	}

	.select-search--multiple:not(.select-search--search) .select-search__input {
		cursor: default;
	}

	.select-search:not(.select-search--multiple) .select-search__input:hover {
		border-color: #2fcc8b;
	}

	.select-search:not(.select-search--multiple) .select-search__select {
		position: absolute;
		z-index: 2;
		top: 44px;
		right: 0;
		left: 0;
		border-radius: 3px;
		overflow: auto;
		max-height: 360px;
	}

	.select-search--multiple .select-search__select {
		position: relative;
		overflow: auto;
		max-height: 260px;
		border-top: 1px solid $black;
		border-radius: 0 0 3px 3px;
	}

	.select-search__not-found {
		height: auto;
		padding: 16px;
		text-align: center;
		color: #888;
	}

	.items-spacing-right {
		display: flex;

		& > .btn {
		}

		&:not(:last-child) {
			margin-right: 1rem;
		}

		&.btn--right {
			margin-left: auto;
		}
	}

	.center-loading {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		color: $brand-blue;

		.spinner-border {
			width: 2em;
			height: 2em;
		}

		&.big {
			font-size: 26px;
		}
	}
}
