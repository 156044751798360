.table-tree {

    header {

        h3 {
            text-transform: capitalize;
        }
    }
}

.table-tree__items {

    .card {
        .card-header {
            cursor: pointer;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        .form__content {
            padding: 0;
        }
    }
}

.table-tree__controls {

    & > button,
    & > a {
        &:not(:first-child) {
            margin-left: .75rem;
        }
    }
}