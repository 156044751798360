@import '../../_vars.scss';

#breadcrumb {
	display: flex;
	font-size: 1.15rem;
	margin: 0 0 2rem 0;
	padding: 0 0 .75rem 0;
	border-bottom: 1px solid rgba($black, .3);
	list-style: none;

	li {
		color: $light-gray;

		a {
			color: inherit;
		}

		svg {
			margin-left: 10px;
		}

		&:not(:last-child) {
			margin-right: .75rem;
		}

		&:last-child {
			color: $black;
			font-weight: bold;
		}
	}
}

.form-label {
    font-weight: bold;
}
