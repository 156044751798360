.admin-layout {
	display: flex;
	height: calc(100vh - 3.75rem);

	& > menu {
		max-width: 220px;
	}

	& > main {
		flex: 1;
		overflow: auto;

		.admin-wrapper {
			max-width: 1370px;
			padding: 3rem 1.5rem;
			margin: 0 auto;
		}
	}
}