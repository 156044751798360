@import "../../../_vars";

.log-graph {
	.center-loading {
		height: calc(100vh - 60px - 6rem);
	}
}

.graph-controls {
	align-items: center;

	& > div {
		&:first-child {
			display: flex;

			& > .rdt:first-child {
				margin-right: 10px;
			}
		}

		&:not(:first-child) {
			& > button {
				height: 3rem;
			}
		}
	}
}

.loading-file {
	display: flex;
	padding: 20px 0;
	justify-content: center;
}
